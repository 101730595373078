<template>
  <div class="flex-column popup_01">
    <div class="top-sub flex-row justify-space-between popup_title">
      <h4 class="pl-30" style="color: white">휴대폰 본인 인증</h4>
      <div class="pr-30">
        <button class="arrow" @click="$emit('cancel')">
          <i class="icon-close color-white "></i>
        </button>
      </div>
    </div>
    <div class="full-height flex-column popup_bottom mt-30 ml-30 mr-30">
      <div class="full-height">
          <p class="step_tit">① 휴대폰번호 입력하세요.</p>
          <div class="bright_input flex">
            <input
                type="text"
                v-model="phoneNumber"
                placeholder="휴대폰 번호 입력">
            <button
                class="btn_l btn_fill_blue ml-10 flex_shrink request_btn" :class="{'disabled' : isRequest}" :disabled="isRequest" @click="request">요청</button>
          </div>
      </div>

      <div class="full-height mt-25">
        <p class="step_tit">② 인증번호를 입력하세요.</p>
        <div class="bright_input flex">
          <input
              type="text"
              v-model="verifyCode"
              placeholder="6자리 인증번호 입력">
        </div>
      </div>
    </div>

    <div class="agree-btn-wrap mt-25 mb-25">
      <button class="request_btn" :class="{'disabled' : !isRequest}" :disabled="!isRequest" style="width: 20%; padding: 13px" @click="confirm">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "smsPopup",
  data() {
    return {
      isRequest: false,
      isConfirm: false,

      phoneNumber: '',
      verifyCode: '',
    }
  },
  methods: {
    request() {

      let regex = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
      if (regex.test(this.phoneNumber)) {
        this.isRequest = true
        this.$emit('request', this.phoneNumber)
      } else {
        this.$bus.$emit('notify', {type: 'error', message: '휴대폰 번호를 제대로 입력해주세요.'})
      }
    },

    confirm() {
      if (this.verifyCode) {
        this.$emit('confirm', this.verifyCode)
      } else {
        this.$bus.$emit('notify', {type: 'error', message: '인증번호를 제대로 입력해주세요.'})
      }
    },
  }
}
</script>

<style scoped>
.popup_01 {
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 7px 7px 5px rgba(0,0,0,0.2);
  z-index: 3;
  background: white;
}

.popup_title {
  border-radius: 20px 20px 0 0
}

.popup_bottom {
  border-radius: 0 0 20px 20px;
}

.full-height, .btn {
  color : black;
}

.step_tit {
  text-align: left;
  margin-bottom: 10px;
  color: black;
}

.flex {
  display: flex;
  flex-direction: row;
}

.request_btn { width: 30%; text-align: center; background-color: #171c61; padding: 10px; font-size: 16px; color: white; border-radius: 30px}
.request_btn.disabled { width: 30%; text-align: center; background-color: #c2c2c2; padding: 10px; font-size: 16px; color: #797979; border-radius: 30px}
</style>